body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Fonts*/
@font-face {
  font-family: "HelveticaRg";
  src: url(/static/media/HelvLight-Regular.5a5e3c2d.woff) format("woff"), url(/static/media/HelvLight-Regular.551445f4.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaBd";
  src: url(/static/media/HELR65W.001b4e8a.woff) format("woff"), url(/static/media/HELR65W.95564630.ttf) format("truetype"); }

/*Font variables*/
.ui.vertical.tabular.menu {
  width: 5.9% !important;
  position: fixed;
  left: 0px;
  z-index: 1;
  border-right: none;
  height: 100%; }
  .ui.vertical.tabular.menu .item {
    padding: 30%;
    padding-left: 25%;
    font-size: 48px;
    color: #ffffff;
    border-radius: 0px;
    text-align: center;
    height: 12.5% !important;
    border: none; }
    .ui.vertical.tabular.menu .item.section-1 {
      background-color: #243F90; }
    .ui.vertical.tabular.menu .item.section-2 {
      background-color: #769B4B; }
    .ui.vertical.tabular.menu .item.section-3 {
      background-color: #198496; }
    .ui.vertical.tabular.menu .item.section-4 {
      background-color: #682074; }
    .ui.vertical.tabular.menu .item.section-5 {
      background-color: #E1504C; }
    .ui.vertical.tabular.menu .item.section-6 {
      background-color: #00758D; }
    .ui.vertical.tabular.menu .item.section-7 {
      background-color: #009FE4; }
    .ui.vertical.tabular.menu .item.section-8 {
      background-color: #D54A78; }
  .ui.vertical.tabular.menu .active.item {
    color: #ffffff;
    border-radius: 0px !important;
    margin: 0px; }

.ui.bottom.attached.segment.active.tab {
  border-left: none;
  border-top: none;
  padding: 0px; }
  .ui.bottom.attached.segment.active.tab .next-button {
    margin-top: 30px;
    text-align: right;
    position: fixed;
    bottom: 10%;
    right: 150px; }
    .ui.bottom.attached.segment.active.tab .next-button button {
      border-radius: 0px;
      background: #243F90;
      border: 1px solid #243F90;
      color: #ffffff;
      font-family: "HelveticaBd";
      padding-left: 50px;
      padding-right: 50px;
      font-size: 18px !important;
      -webkit-animation: button-animation 0.5s infinite alternate;
              animation: button-animation 0.5s infinite alternate; }
      .ui.bottom.attached.segment.active.tab .next-button button:hover, .ui.bottom.attached.segment.active.tab .next-button button:focus {
        background: #243F90;
        border: 1px solid #243F90;
        color: #ffffff; }
  .ui.bottom.attached.segment.active.tab.section-1-content {
    background-color: #243F90;
    height: 102vh;
    overflow: hidden;
    border: 0; }
    .ui.bottom.attached.segment.active.tab.section-1-content .next-button button {
      background: #243F90;
      border: 1px solid #243F90; }
      .ui.bottom.attached.segment.active.tab.section-1-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-1-content .next-button button:focus {
        background: #243F90;
        border: 1px solid #243F90; }
    .ui.bottom.attached.segment.active.tab.section-1-content .score-card {
      color: #243F90; }
  .ui.bottom.attached.segment.active.tab.section-2-content {
    background-color: #769B4B;
    height: 102vh;
    overflow: hidden;
    border: 0; }
    .ui.bottom.attached.segment.active.tab.section-2-content .next-button button {
      background: #769B4B;
      border: 1px solid #769B4B; }
      .ui.bottom.attached.segment.active.tab.section-2-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-2-content .next-button button:focus {
        background: #769B4B;
        border: 1px solid #769B4B; }
    .ui.bottom.attached.segment.active.tab.section-2-content .score-card {
      color: #769B4B; }
  .ui.bottom.attached.segment.active.tab.section-3-content {
    background-color: #198496;
    height: 102vh;
    overflow: hidden; }
    .ui.bottom.attached.segment.active.tab.section-3-content .next-button button {
      background: #198496;
      border: 1px solid #198496; }
      .ui.bottom.attached.segment.active.tab.section-3-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-3-content .next-button button:focus {
        background: #198496;
        border: 1px solid #198496; }
    .ui.bottom.attached.segment.active.tab.section-3-content .score-card {
      color: #198496; }
  .ui.bottom.attached.segment.active.tab.section-4-content {
    background-color: #682074;
    height: 102vh;
    overflow: hidden; }
    .ui.bottom.attached.segment.active.tab.section-4-content .next-button button {
      background: #682074;
      border: 1px solid #682074; }
      .ui.bottom.attached.segment.active.tab.section-4-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-4-content .next-button button:focus {
        background: #682074;
        border: 1px solid #682074; }
    .ui.bottom.attached.segment.active.tab.section-4-content .score-card {
      color: #682074; }
  .ui.bottom.attached.segment.active.tab.section-5-content {
    background-color: #E1504C;
    height: 102vh;
    overflow: hidden; }
    .ui.bottom.attached.segment.active.tab.section-5-content .next-button button {
      background: #E1504C;
      border: 1px solid #E1504C; }
      .ui.bottom.attached.segment.active.tab.section-5-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-5-content .next-button button:focus {
        background: #E1504C;
        border: 1px solid #E1504C; }
    .ui.bottom.attached.segment.active.tab.section-5-content .score-card {
      color: #E1504C; }
  .ui.bottom.attached.segment.active.tab.section-6-content {
    background-color: #00758D;
    height: 102vh;
    overflow: hidden; }
    .ui.bottom.attached.segment.active.tab.section-6-content .next-button button {
      background: #00758D;
      border: 1px solid #00758D; }
      .ui.bottom.attached.segment.active.tab.section-6-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-6-content .next-button button:focus {
        background: #00758D;
        border: 1px solid #00758D; }
    .ui.bottom.attached.segment.active.tab.section-6-content .score-card {
      color: #00758D; }
  .ui.bottom.attached.segment.active.tab.section-7-content {
    background-color: #009FE4;
    height: 102vh;
    overflow: hidden; }
    .ui.bottom.attached.segment.active.tab.section-7-content .next-button button {
      background: #009FE4;
      border: 1px solid #009FE4; }
      .ui.bottom.attached.segment.active.tab.section-7-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-7-content .next-button button:focus {
        background: #009FE4;
        border: 1px solid #009FE4; }
    .ui.bottom.attached.segment.active.tab.section-7-content .score-card {
      color: #009FE4; }
  .ui.bottom.attached.segment.active.tab.section-8-content {
    background-color: #D54A78;
    height: 102vh;
    overflow: hidden; }
    .ui.bottom.attached.segment.active.tab.section-8-content .next-button button {
      background: #D54A78;
      border: 1px solid #D54A78; }
      .ui.bottom.attached.segment.active.tab.section-8-content .next-button button:hover, .ui.bottom.attached.segment.active.tab.section-8-content .next-button button:focus {
        background: #D54A78;
        border: 1px solid #D54A78; }
    .ui.bottom.attached.segment.active.tab.section-8-content .score-card {
      color: #D54A78; }

@-webkit-keyframes button-animation {
  from {
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

@keyframes button-animation {
  from {
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

/*Fonts*/
@font-face {
  font-family: "HelveticaRg";
  src: url(/static/media/HelvLight-Regular.5a5e3c2d.woff) format("woff"), url(/static/media/HelvLight-Regular.551445f4.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaBd";
  src: url(/static/media/HELR65W.001b4e8a.woff) format("woff"), url(/static/media/HELR65W.95564630.ttf) format("truetype"); }

/*Font variables*/
/*Fonts*/
@font-face {
  font-family: "HelveticaRg";
  src: url(/static/media/HelvLight-Regular.5a5e3c2d.woff) format("woff"), url(/static/media/HelvLight-Regular.551445f4.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaBd";
  src: url(/static/media/HELR65W.001b4e8a.woff) format("woff"), url(/static/media/HELR65W.95564630.ttf) format("truetype"); }

/*Font variables*/
.home-main-div {
  width: 100%;
  margin: 0px; }
  .home-main-div .home-content {
    background: #39519A;
    padding: 5%;
    font-family: "HelveticaRg";
    color: #ffffff;
    max-width: 80%;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    width: 70%;
    left: -700px;
    -webkit-animation: extend-div 1s ease-out forwards;
            animation: extend-div 1s ease-out forwards; }
    .home-main-div .home-content .home-title {
      font-size: 40px;
      white-space: nowrap;
      overflow: hidden;
      line-height: normal;
      position: relative;
      margin-bottom: 1%;
      font-family: "HelveticaBd";
      text-align: center; }
    .home-main-div .home-content .home-description {
      font-size: 40px;
      position: relative;
      line-height: normal;
      text-align: center; }
  .home-main-div .start-button {
    text-align: center;
    left: 0;
    right: 0;
    top: 70%;
    z-index: 35;
    position: absolute; }
    .home-main-div .start-button a {
      padding: 15px 50px;
      font-size: 16px;
      background: #39519A !important; }
    .home-main-div .start-button:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      transition: 0.2s linear; }
  .home-main-div .logo-image {
    position: absolute;
    top: 50%;
    left: -130px;
    max-width: 35%;
    z-index: 2;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); }
    .home-main-div .logo-image img.ui.image.logo-white {
      position: absolute;
      width: 40%;
      left: 28%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      top: 50%; }

.our-methodology {
  color: #00008B;
  font-family: "HelveticaBd";
  position: fixed;
  bottom: 50px;
  cursor: pointer;
  right: 100px;
  font-size: 1.3em; }

@-webkit-keyframes extend-div {
  0% {
    left: -700px; }
  100% {
    left: 183px; } }

@keyframes extend-div {
  0% {
    left: -700px; }
  100% {
    left: 183px; } }

@keyframes fade-in-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade-in-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*Fonts*/
@font-face {
  font-family: "HelveticaRg";
  src: url(/static/media/HelvLight-Regular.5a5e3c2d.woff) format("woff"), url(/static/media/HelvLight-Regular.551445f4.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaBd";
  src: url(/static/media/HELR65W.001b4e8a.woff) format("woff"), url(/static/media/HELR65W.95564630.ttf) format("truetype"); }

/*Font variables*/
.about-you {
  background-color: #84A65A;
  position: fixed;
  height: 100%; }
  .about-you .column.lens-div {
    padding: 0px !important;
    margin: 0px !important;
    position: absolute; }
    .about-you .column.lens-div .lens {
      position: absolute;
      top: -140px;
      bottom: 0px;
      right: -60px;
      left: 0px; }
      .about-you .column.lens-div .lens svg {
        max-width: 100%; }
    .about-you .column.lens-div .heading {
      font-family: "HelveticaBd"; }
  .about-you .tell-us-section {
    font-size: 3vw;
    line-height: 4vw;
    color: #ffffff;
    margin-top: 4em; }
  .about-you .about-you-content {
    margin: 0 20%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .about-you .about-you-content .gender-select {
      margin: 0px; }
      .about-you .about-you-content .gender-select .title {
        text-align: center;
        display: block; }
      .about-you .about-you-content .gender-select img {
        cursor: pointer;
        max-width: 50%; }
      .about-you .about-you-content .gender-select .ui.circular.button {
        background-color: #77BD20;
        color: #ffffff;
        position: absolute;
        right: 0px;
        top: -15px; }
    .about-you .about-you-content .other-info .range-slider, .about-you .about-you-content .other-info .dropdown-select {
      width: 100%; }
    .about-you .about-you-content .other-info .heading {
      margin-bottom: 30px; }
    .about-you .about-you-content .other-info .submit-button-div {
      display: block;
      text-align: right; }
      .about-you .about-you-content .other-info .submit-button-div button {
        background-color: #84A65A;
        color: #ffffff; }
        .about-you .about-you-content .other-info .submit-button-div button:focus, .about-you .about-you-content .other-info .submit-button-div button :hover {
          background-color: #84A65A;
          color: #ffffff; }

/*Fonts*/
@font-face {
  font-family: "HelveticaRg";
  src: url(/static/media/HelvLight-Regular.5a5e3c2d.woff) format("woff"), url(/static/media/HelvLight-Regular.551445f4.ttf) format("truetype"); }

@font-face {
  font-family: "HelveticaBd";
  src: url(/static/media/HELR65W.001b4e8a.woff) format("woff"), url(/static/media/HELR65W.95564630.ttf) format("truetype"); }

/*Font variables*/
.sc-wrapper {
  position: relative;
  height: 435px; }

/* Score card - large */
.fn-score-card {
  background: url("/score-card-sprite.png") no-repeat;
  position: absolute;
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  cursor: pointer;
  opacity: 0;
  -webkit-animation: score 1s forwards;
  animation: score 1s forwards;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5));
  transition: 0.25s linear;
  font-size: 16px;
  font-family: "HelveticaRg"; }
  .fn-score-card span {
    font-size: 20px; }

.fn-score-card:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  transition: 0.25s linear;
  font-family: "HelveticaBd";
  font-size: 16px; }

.fn-score-card > div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  width: 60%;
  margin: 0 auto; }

.disc {
  background: url("/score-card-sprite.png") no-repeat -868px -30px;
  position: absolute;
  width: 182px;
  height: 182px;
  top: 156px;
  left: 152px; }

.disc > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #6C6B6B; }

.sc-section-1 {
  background-position: -31px -39px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  width: 176px;
  height: 165px;
  top: 16px;
  left: 156px; }

.sc-section-2 {
  background-position: -239px -31px;
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s;
  width: 180px;
  height: 180px;
  top: 34px;
  left: 278px; }

.sc-section-3 {
  background-position: -457px -33px;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  width: 165px;
  height: 176px;
  top: 160px;
  left: 312px; }

.sc-section-4 {
  background-position: -659px -31px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  width: 180px;
  height: 180px;
  top: 282px;
  left: 278px; }

.sc-section-5 {
  background-position: -31px -250px;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  width: 176px;
  height: 165px;
  top: 316px;
  left: 156px; }

.sc-section-6 {
  background-position: -239px -242px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  width: 180px;
  height: 180px;
  top: 282px;
  left: 30px; }

.sc-section-7 {
  background-position: -457px -244px;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
  width: 165px;
  height: 176px;
  top: 160px;
  left: 10px; }

.sc-section-8 {
  background-position: -659px -241px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  width: 180px;
  height: 180px;
  top: 34px;
  left: 30px; }

.score-value {
  display: none;
  font-size: 50px;
  font-family: "HelveticaBd"; }

.score-value.small {
  font-size: 25px; }

.card:hover .score-value, .hoveredCard .score-value {
  display: block; }

/* Score card - small */
.score-card-small {
  background: url("/score-card-sprite.png") no-repeat;
  position: absolute;
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  opacity: 0;
  -webkit-animation: score 1s forwards;
  animation: score 1s forwards;
  cursor: pointer;
  transition: 0.25s linear;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5)); }
  .score-card-small span {
    font-size: 14px; }

.score-card-small:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  transition: 0.25s linear;
  font-family: "HelveticaBd"; }

.hoveredCard {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  transition: 0.25s linear;
  font-family: "HelveticaBd"; }

.score-card-small > div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  width: 60%;
  margin: 0 auto;
  font-size: 12px; }

.disc-small {
  background: url("/score-card-sprite.png") no-repeat -892px -283px;
  width: 122px;
  height: 121px;
  position: absolute;
  top: 106px;
  left: 110px; }

.disc-small > div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #6C6B6B;
  font-size: 12px; }

.sc-section-small-1 {
  background-position: -60px -510px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  width: 119px;
  height: 112px;
  top: 8px;
  left: 110px; }

.sc-section-small-2 {
  background-position: -268px -505px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  width: 122px;
  height: 122px;
  top: 22px;
  left: 196px; }

.sc-section-small-3 {
  background-position: -483px -507px;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  width: 112px;
  height: 119px;
  top: 108px;
  left: 220px; }

.sc-section-small-4 {
  background-position: -688px -505px;
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
  width: 122px;
  height: 122px;
  top: 194px;
  left: 196px; }

.sc-section-small-5 {
  background-position: -60px -721px;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  width: 119px;
  height: 112px;
  top: 218px;
  left: 110px; }

.sc-section-small-6 {
  background-position: -268px -716px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  width: 122px;
  height: 122px;
  top: 194px;
  left: 24px; }

.sc-section-small-7 {
  background-position: -483px -718px;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
  width: 112px;
  height: 119px;
  top: 108px;
  left: 10px; }

.sc-section-small-8 {
  background-position: -688px -715px;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  width: 122px;
  height: 122px;
  top: 22px;
  left: 24px; }

.score-cards-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  padding-left: 100px;
  top: 48%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100%;
  overflow-y: auto; }

.final-score-section {
  background: #243F90;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.section-details-sp {
  display: table-cell;
  vertical-align: middle; }
  .section-details-sp > img {
    width: 200px;
    margin-left: 100px; }

.center-overall-score-large {
  font-family: "HelveticaBd";
  font-size: 21px; }

.center-overall-score-large div {
  font-size: 21px; }

.center-overall-score-large .obtained {
  font-size: 35px; }

.score-header {
  font-size: 36px !important;
  font-family: "HelveticaBd";
  color: #303030d9 !important; }
  .score-header.people-like-you {
    font-size: 24px !important; }
    .score-header.people-like-you > span {
      font-size: 25px;
      cursor: pointer; }
  .score-header > span {
    font-size: 25px;
    cursor: pointer; }

.how-final-score {
  position: fixed;
  bottom: 40px;
  right: 100px;
  font-family: "HelveticaBd";
  font-size: 15px;
  color: #00008B;
  cursor: pointer; }

.low-score-section-names, .phone-number {
  font-family: "HelveticaBd";
  color: #4183c4; }

.low-score-section-names.normal {
  color: #000000; }

.phone-number {
  font-size: 18px; }

.center-overall-score-small {
  font-family: "HelveticaBd"; }
  .center-overall-score-small .obtained {
    font-size: 30px; }

.info-icon-scores {
  position: fixed;
  left: 150px;
  bottom: 5%;
  color: #fff;
  z-index: 10;
  font-size: 60px;
  cursor: pointer;
  -webkit-animation: button-animation 0.5s infinite alternate;
          animation: button-animation 0.5s infinite alternate; }

.score-final-information {
  padding: 25px;
  font-size: 18px !important;
  font-family: "HelveticaRg"; }

#result {
  content: '';
  display: table;
  clear: both;
  width: 100%; }

@-webkit-keyframes score {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes score {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media (min-width: 1634px) {
  .mini-score-cards-container {
    width: 56% !important; } }

@media (min-width: 1600px) {
  #logo {
    margin-left: 150px; } }

@media (min-width: 1800px) {
  .your-score-title {
    margin-left: -125px; } }

@media (max-width: 1322px) {
  html {
    font-size: 12px; } }

body {
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: "HelveticaRg"; }

.bold-font {
  font-family: "HelveticaBd"; }

.warning-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11111;
  background-color: #000;
  opacity: 0.95;
  color: #fff; }
  .warning-cover .warning-text {
    margin: 0 auto;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    position: absolute;
    left: 10%;
    right: 0; }

.survey-list > .grid > .column {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important; }

.survey-list .column.lens-div {
  margin-left: -30px;
  padding: 0px !important;
  margin: 0px !important;
  position: absolute;
  height: 100vh; }
  .survey-list .column.lens-div .lens {
    position: absolute;
    top: -140px;
    bottom: 0px;
    right: -60px;
    left: 0px; }
    .survey-list .column.lens-div .lens svg {
      max-width: 100%; }

/*Question list inside lens division*/
.questions-list {
  padding: 0px 20%;
  top: 35%;
  height: 28vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative; }
  .questions-list .question-item:not(:first-child) {
    margin-top: 8%; }
  .questions-list .question-item .question {
    font-size: 22px;
    color: #525252;
    line-height: initial; }
    .questions-list .question-item .question .info-popup {
      margin-left: 10px;
      font-size: 13px;
      cursor: pointer !important; }
    .questions-list .question-item .question > span {
      display: table-cell; }
  .questions-list::-webkit-scrollbar {
    display: none; }
  .questions-list > .blur {
    pointer-events: none;
    opacity: 0.2; }
    .questions-list > .blur .question {
      font-size: 20px; }
  .questions-list > .active {
    opacity: 1; }

/*Answer*/
.answer {
  padding: 20px; }

/*Active dot*/
.rc-slider-dot-active {
  background-image: url(/static/media/done.e249914e.svg); }

/*Primary button*/
.ui.primary.button {
  border-radius: 0px;
  background: transparent;
  border: 1px solid #A8A8A8;
  color: #333333;
  font-family: "HelveticaRg"; }
  .ui.primary.button:hover, .ui.primary.button:focus {
    background: #78BE20;
    border: 1px solid #78BE20;
    color: #ffffff; }
  .ui.primary.button.active {
    background: #78BE20;
    border: 1px solid #78BE20; }

/*Secondary Button*/
.ui.secondary.button {
  border-radius: 0px;
  background: #243F90;
  border: 1px solid #243F90;
  color: #ffffff;
  font-family: "HelveticaRg"; }
  .ui.secondary.button:hover, .ui.secondary.button:focus {
    background: #243F90;
    border: 1px solid #243F90;
    color: #ffffff; }

/*Reaction list*/
.reaction-list > i {
  font-size: 40px;
  color: #A8A8A8;
  border: 5px solid transparent; }
  .reaction-list > i:hover, .reaction-list > i:focus {
    cursor: pointer;
    background: #78BE20;
    border-radius: 50%;
    border: 5px solid #78BE20;
    color: #ffffff; }

/*Section main div*/
.ui.grid.section-main-div {
  margin: 0px !important;
  width: 100% !important; }

/*Section details*/
.ui.grid > .section-details {
  padding: 2% !important;
  /*Score card*/ }
  .ui.grid > .section-details .ui.list.section-list {
    color: #ffffff;
    position: absolute;
    top: 30%;
    -webkit-transform-origin: translateY(-50%);
    transform-origin: translateY(-50%); }
    .ui.grid > .section-details .ui.list.section-list .item.active {
      font-size: 24px;
      font-family: "HelveticaBd"; }
    .ui.grid > .section-details .ui.list.section-list .item.blur {
      opacity: 0.2; }
  .ui.grid > .section-details .score-card {
    position: fixed;
    bottom: 5%;
    display: table;
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    background-repeat: no-repeat;
    margin: 0 auto;
    z-index: 1205; }
    .ui.grid > .section-details .score-card > div {
      margin-top: 5px;
      position: relative; }
    .ui.grid > .section-details .score-card .your-score {
      font-size: 4.2em;
      font-family: "HelveticaBd"; }
    .ui.grid > .section-details .score-card > img {
      position: absolute;
      left: 0px;
      bottom: 0px;
      max-width: 200px; }

/*Button range*/
.button-range {
  position: relative;
  padding: 15px; }
  .button-range .track {
    position: absolute;
    top: 9px;
    height: 5px;
    background-color: #EAEAEA;
    width: 100%;
    left: 12px; }
  .button-range .ui.button.circular {
    position: absolute;
    top: 0px;
    background-color: #EAEAEA;
    padding: 6px;
    font-size: 10px; }
    .button-range .ui.button.circular.active {
      top: -7px;
      font-size: 20px;
      padding: 10px; }
  .button-range .ui.circular.label.active {
    position: absolute;
    top: 0px;
    background-color: #EAEAEA;
    padding: 6px;
    font-size: 10px; }
    .button-range .ui.circular.label.active.active {
      top: -7px;
      font-size: 17px;
      padding: 10px; }
  .button-range span.dot-text {
    position: absolute;
    top: 35px;
    color: #000;
    font-size: 13px;
    width: 100px; }
  .button-range.with-na {
    padding: 45px;
    padding-left: 0px; }
    .button-range.with-na .ui.checkbox {
      margin-top: 8%;
      margin-left: -3%; }
    .button-range.with-na .goals-title {
      padding: 0px !important; }
      .button-range.with-na .goals-title > div .info-popup {
        font-size: 14px; }

/*Button range multiple*/
.button-range-multiple {
  position: relative; }
  .button-range-multiple span.dot-text {
    position: absolute;
    top: 35px;
    color: #000;
    font-size: 10px;
    width: 100px; }
  .button-range-multiple .title {
    font-size: 12px;
    color: #525252; }
  .button-range-multiple .button-range {
    position: relative;
    padding: 15px;
    margin-bottom: 10%; }
    .button-range-multiple .button-range .ui.circular.label.active {
      position: absolute;
      top: 0px;
      background-color: #EAEAEA;
      padding: 6px;
      font-size: 10px; }
      .button-range-multiple .button-range .ui.circular.label.active.active {
        top: -7px;
        font-size: 17px;
        padding: 10px; }

/*Yes or no button with dropdown*/
.yes-or-no {
  text-align: center; }
  .yes-or-no .ui.button.top.left.pointing.dropdown.yes-or-no-dropdown {
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #bbb;
    padding: 18px;
    font-size: 16px; }
    .yes-or-no .ui.button.top.left.pointing.dropdown.yes-or-no-dropdown:hover, .yes-or-no .ui.button.top.left.pointing.dropdown.yes-or-no-dropdown:focus {
      background-color: #78BE20;
      border: 1px solid #78BE20;
      color: #ffffff; }
    .yes-or-no .ui.button.top.left.pointing.dropdown.yes-or-no-dropdown > .menu {
      border-radius: 0px;
      z-index: 100; }
      .yes-or-no .ui.button.top.left.pointing.dropdown.yes-or-no-dropdown > .menu > .item {
        padding: 20px !important;
        font-size: 15px; }
  .yes-or-no .selected-answer {
    width: 80%;
    margin: auto;
    background-color: #C9C9C9; }
    .yes-or-no .selected-answer .ui.grid {
      margin: 25px; }

/*Radio select*/
.radio-select .ui.radio.checkbox {
  min-height: 15px;
  padding: 15px;
  font-size: 15px; }
  .radio-select .ui.radio.checkbox label:after {
    background-color: #78BE20 !important; }

/*Range slider*/
.range-slider {
  cursor: pointer; }
  .range-slider .rangeslider-horizontal {
    border-radius: 10px; }
    .range-slider .rangeslider-horizontal .rangeslider__fill {
      border-radius: 10px;
      background-color: #404B54;
      box-shadow: none; }
    .range-slider .rangeslider-horizontal .rangeslider__handle {
      border: none;
      box-shadow: none;
      background-color: #404B54; }
      .range-slider .rangeslider-horizontal .rangeslider__handle:after {
        background-color: #404B54;
        box-shadow: none; }
      .range-slider .rangeslider-horizontal .rangeslider__handle:focus {
        outline: none; }
  .range-slider .heading {
    margin-bottom: 35px; }
    .range-slider .heading .value {
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.16);
      padding: 9px;
      border-radius: 3px;
      float: right; }

/*Dropdown select*/
.dropdown-select .ui.fluid.selection.dropdown {
  border-radius: 0px;
  background-color: #F2F2F2;
  border: none; }
  .dropdown-select .ui.fluid.selection.dropdown .menu {
    border: none;
    border-radius: 0px;
    background-color: #F2F2F2; }

.ui[class*="top aligned"].grid > .column:not(.row) {
  align-self: center !important; }

.active-dot {
  font-family: "HelveticaBd";
  font-size: 14px !important; }

.ui.vertical.tabular.menu .item.visited-section {
  background: #707070;
  border-bottom: 1px solid #cac5c526; }

.how-information-content {
  padding: 5% !important; }
  .how-information-content p {
    font-size: 15px; }

.review-header {
  position: fixed;
  left: 55%;
  font-size: 20px;
  right: 0;
  top: 5%; }

.score-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
  max-height: 150px;
  font-family: "HelveticaBd";
  padding-top: 10%; }

.score-section-title-normal {
  font-size: 14px;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  margin-top: 30% !important;
  height: 200px; }

.score-section-title-scored {
  font-size: 12px;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  margin-top: 25% !important;
  height: 200px; }

.icon-scroll, .icon-scroll:before {
  position: absolute;
  left: 50%;
  z-index: 1208; }

.icon-scroll {
  width: 20px;
  height: 35px;
  margin-left: -20px;
  top: 50%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #000;
  border-radius: 25px;
  left: 90%; }

.icon-scroll:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #000;
  margin-left: -2px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
          animation-name: scroll; }

@-webkit-keyframes scroll {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(23px);
            transform: translateY(23px); } }

@keyframes scroll {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(23px);
            transform: translateY(23px); } }

.dimmer-wrapper-container {
  z-index: 1208;
  position: fixed;
  bottom: 50px;
  right: 50px;
  font-size: 18px;
  width: 350px;
  color: #bbb !important;
  float: left;
  font-family: "HelveticaRg"; }

.dimmer-wrapper-section {
  z-index: 1208;
  position: fixed;
  bottom: 5px;
  left: 120px;
  font-size: 18px;
  width: 350px;
  color: #bbb !important;
  float: left;
  font-family: "HelveticaRg"; }

.ui.modal .close {
  background: #fff;
  color: #ff3000 !important;
  border-radius: 50%;
  z-index: 1218; }

.info.circular.icon.info-popup {
  cursor: pointer; }

.ie-notice {
  background: rgba(251, 203, 204, 0.72);
  position: fixed;
  top: 0;
  right: 10%;
  left: 10%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "HelveticaBd"; }
  .ie-notice .ie-notice-content {
    font-size: 13px;
    color: rgba(48, 48, 48, 0.85);
    text-align: center; }

@media screen and (max-width: 1281px) and (min-height: 796px) {
  .lens > img {
    max-height: 100vh;
    width: auto;
    height: auto;
    max-width: unset;
    top: 15%;
    right: 0;
    left: 12%;
    -webkit-transform: scale(1.32);
    transform: scale(1.32); }
  .survey-list .column.lens-div {
    height: 100vh; }
    .survey-list .column.lens-div .lens {
      position: absolute;
      top: -140px;
      bottom: 0px;
      right: -60px;
      left: 0px; }
      .survey-list .column.lens-div .lens svg {
        max-width: 100%;
        -webkit-transform: scale(1.5);
        transform: scale(1.5); }
  /*Question list inside lens division*/
  .questions-list {
    padding: 0 15%; }
    .questions-list .question-item .question {
      font-size: 18px; }
      .questions-list .question-item .question .info-popup {
        font-size: 14px;
        cursor: pointer !important; }
    .questions-list > .blur .question {
      font-size: 17px; }
  .score-card {
    bottom: 10%; }
  .ui.bottom.attached.segment.active.tab .next-button {
    bottom: 15%;
    right: 175px; }
  .score-cards-container {
    top: 50%; }
  .section-details-sp > img {
    width: 150px;
    margin-left: 100px; } }

.App {
  text-align: center;
}

.App-logo{
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
.outline-lens-logo {
  -webkit-animation: App-logo-spin infinite 10s linear;
          animation: App-logo-spin infinite 10s linear;
}
.score-card-image{
  -webkit-animation: App-logo-spin infinite 10s linear;
          animation: App-logo-spin infinite 10s linear;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.dotNav {
  border-radius: 50%;
  cursor: pointer;
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 10px; }

.nav-indicator {
  position: fixed;
  display: flex;
  align-items: center;
  top: 5%;
  right: 10%; }

.dot {
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 10px; }
  .dot.active-1 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #243F9040;
    border: 1px solid #243F9070;
    -webkit-animation: pulse-1 2s infinite;
            animation: pulse-1 2s infinite; }
  .dot.active-2 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #769B4B40;
    border: 1px solid #769B4B50;
    -webkit-animation: pulse-2 2s infinite;
            animation: pulse-2 2s infinite; }
  .dot.active-3 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #19849640;
    border: 1px solid #19849650;
    -webkit-animation: pulse-3 2s infinite;
            animation: pulse-3 2s infinite; }
  .dot.active-4 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #68207440;
    border: 1px solid #68207450;
    -webkit-animation: pulse-4 2s infinite;
            animation: pulse-4 2s infinite; }
  .dot.active-5 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #E1504C40;
    border: 1px solid #E1504C50;
    -webkit-animation: pulse-5 2s infinite;
            animation: pulse-5 2s infinite; }
  .dot.active-6 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #00758D40;
    border: 1px solid #00758D50;
    -webkit-animation: pulse-6 2s infinite;
            animation: pulse-6 2s infinite; }
  .dot.active-7 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #009FE440;
    border: 1px solid #009FE450;
    -webkit-animation: pulse-7 2s infinite;
            animation: pulse-7 2s infinite; }
  .dot.active-8 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #D54A7840;
    border: 1px solid #D54A7850;
    -webkit-animation: pulse-8 2s infinite;
            animation: pulse-8 2s infinite; }

.section-1 {
  background-color: #243F90; }

.section-2 {
  background-color: #769B4B; }

.section-3 {
  background-color: #198496; }

.section-4 {
  background-color: #682074; }

.section-5 {
  background-color: #E1504C; }

.section-6 {
  background-color: #00758D; }

.section-7 {
  background-color: #009FE4; }

.section-8 {
  background-color: #D54A78; }

@-webkit-keyframes pulse-1 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #243F9040; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-1 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #243F9040; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@-webkit-keyframes pulse-2 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #769B4B40; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-2 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #769B4B40; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@-webkit-keyframes pulse-3 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #19849640; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-3 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #19849640; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@-webkit-keyframes pulse-4 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #68207440; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-4 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #68207440; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@-webkit-keyframes pulse-5 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #E1504C40; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-5 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #E1504C40; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@-webkit-keyframes pulse-6 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #00758D40; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-6 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #00758D40; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@-webkit-keyframes pulse-7 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #009FE440; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-7 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #009FE440; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@-webkit-keyframes pulse-8 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #D54A7840; }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

@keyframes pulse-8 {
  0% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #D54A7840; }
  70% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff; }
  100% {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff; } }

