@import './tab.scss';
@import './variables.scss';
@import './home.scss';
@import './about.scss';
@import './scoreCard.scss';

body {
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: $helvetica-regular;
}
.bold-font{
  font-family: $helvetica-bold;
}
.warning-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11111;
  background-color: #000;
  opacity: 0.95;
  color: #fff;
    .warning-text {
      margin: 0 auto;
      transform: translateY(-50%);
      top: 50%;
      position: absolute;
      left: 10%;
      right: 0;
    }
}
.survey-list {
  > .grid {
    > .column {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .column.lens-div {
    margin-left: -30px;
    padding: 0px !important;
    margin: 0px !important;
    position: absolute;
    height: 100vh;
    .lens {
      position: absolute;
      top: -140px;
      bottom: 0px;
      right: -60px;
      left: 0px;

      svg {
        max-width: 100%;
      }
    }
  }
}

/*Question list inside lens division*/
.questions-list {
  padding: 0px 20%;
  top: 35%;
  height: 28vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;

  .question-item {
    &:not(:first-child) {
      margin-top: 8%;
    }

    .question {
      font-size: 22px;
      color: $question-font-color;
      line-height: initial;

      .info-popup {
        margin-left: 10px;
        font-size: 13px;
        cursor: pointer !important;
      }
      > span {
        display:table-cell;
      }

    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  > .blur {
    pointer-events: none;
    opacity: 0.2;

    .question {
      font-size: 20px;
    }
  }

  > .active {
    opacity: 1;
  }
}

/*Answer*/
.answer {
  padding: 20px;
}

/*Active dot*/
.rc-slider-dot-active {
  background-image: url("../done.svg");
}

/*Primary button*/
.ui.primary.button {
  border-radius: 0px;
  background: transparent;
  border: 1px solid $button-border-color;
  color: $button-text-color;
  font-family: $helvetica-regular;

  &:hover, &:focus {
    background: $button-hover-color;
    border: 1px solid $button-hover-color;
    color: #ffffff;
  }

  &.active {
    background: $button-hover-color;
    border: 1px solid $button-hover-color;
  }
}

/*Secondary Button*/
.ui.secondary.button {
  border-radius: 0px;
  background: $section-1-color;
  border: 1px solid $section-1-color;
  color: #ffffff;
  font-family: $helvetica-regular;

  &:hover, &:focus {
    background: $section-1-color;
    border: 1px solid $section-1-color;
    color: #ffffff;
  }
}

/*Reaction list*/
.reaction-list > i {
  font-size: 40px;
  color: $reaction-color;
  border: 5px solid transparent;

  &:hover, &:focus {
    cursor: pointer;
    background: $button-hover-color;
    border-radius: 50%;
    border: 5px solid $button-hover-color;
    color: #ffffff;
  }
}

/*Section main div*/
.ui.grid.section-main-div {
  margin: 0px !important;
  width: 100% !important;
}

/*Section details*/
.ui.grid > .section-details {
  padding: 2% !important;

  .ui.list.section-list {
    color: #ffffff;
    position: absolute;
    top: 30%;
    -webkit-transform-origin: translateY(-50%);
    -moz-transform-origin: translateY(-50%);
    -ms-transform-origin: translateY(-50%);
    -o-transform-origin: translateY(-50%);
    transform-origin: translateY(-50%);
    .item {
      &.active {
        font-size: 24px;
        font-family: $helvetica-bold;
      }

      &.blur {
        opacity: 0.2;
      }
    }
  }

  /*Score card*/
  .score-card {
    //margin-top: 100px;
    position: fixed;
    bottom: 5%;
    display: table;
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    background-repeat: no-repeat;
    //padding-left: 2em;
    margin: 0 auto;
    z-index: 1205;
    > div {
      margin-top: 5px;
      position: relative;
    }
    .your-score {
      font-size: 4.2em;
      font-family: $helvetica-bold;
    }

    > img {
      position: absolute;
      left: 0px;
      bottom: 0px;
      max-width: 200px;
      //z-index:1088;
    }
  }
}

/*Button range*/
.button-range {
  position: relative;
  padding: 15px;

  .track {
    position: absolute;
    top: 9px;
    height: 5px;
    background-color: $track-color;
    width: 100%;
    left: 12px;
  }

  .ui.button.circular {
    position: absolute;
    top: 0px;
    background-color: $track-color;
    padding: 6px;
    font-size: 10px;

    &.active {
      top: -7px;
      font-size: 20px;
      padding: 10px;
    }
  }

  .ui.circular.label.active {
    position: absolute;
    top: 0px;
    background-color: $track-color;
    padding: 6px;
    font-size: 10px;

    &.active {
      top: -7px;
      font-size: 17px;
      padding: 10px;
    }
  }

  span.dot-text {
    position: absolute;
    top: 35px;
    color: $dot-text-color;
    font-size: 13px;
    width: 100px;
  }

  &.with-na {
    padding: 45px;
    padding-left: 0px;

    .ui.checkbox {
      margin-top: 8%;
      margin-left: -3%;
    }

    .goals-title {
      padding: 0px !important;
      >div {
        .info-popup {
          font-size: 14px;
        }
      }
    }
  }
}


/*Button range multiple*/
.button-range-multiple {
  position: relative;

  span.dot-text {
    position: absolute;
    top: 35px;
    color: $dot-text-color;
    font-size: 10px;
    width: 100px;
  }

  .title {
    font-size: 12px;
    color: #525252;
  }

  .button-range {
    position: relative;
    padding: 15px;
    margin-bottom: 10%;

    .ui.circular.label.active {
      position: absolute;
      top: 0px;
      background-color: $track-color;
      padding: 6px;
      font-size: 10px;

      &.active {
        top: -7px;
        font-size: 17px;
        padding: 10px;
      }
    }
  }

}

/*Yes or no button with dropdown*/
.yes-or-no {
  text-align: center;

  .ui.button.top.left.pointing.dropdown.yes-or-no-dropdown {
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #bbb;
    padding: 18px;
    font-size: 16px;

    &:hover, &:focus {
      background-color: $button-hover-color;
      border: 1px solid $button-hover-color;
      color: #ffffff;
    }

    > .menu {
      border-radius: 0px;
      z-index: 100;

      > .item {
        padding: 20px !important;
        font-size: 15px;
      }
    }

  }

  .selected-answer {
    width: 80%;
    margin: auto;
    background-color: #C9C9C9;

    .ui.grid {
      margin: 25px;
    }
  }
}

/*Radio select*/
.radio-select {
  .ui.radio.checkbox {
    min-height: 15px;
    padding: 15px;
    font-size: 15px;

    label {
      &:after {
        background-color: $button-hover-color !important;
      }
    }
  }
}


/*Range slider*/
.range-slider {
  cursor: pointer;
  .rangeslider-horizontal {
    border-radius: 10px;

    .rangeslider__fill {
      border-radius: 10px;
      background-color: $range-slider-bg-color;
      box-shadow: none;
    }

    .rangeslider__handle {
      border: none;
      box-shadow: none;
      background-color: $range-slider-bg-color;

      &:after {
        background-color: $range-slider-bg-color;
        box-shadow: none;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .heading {
    margin-bottom: 35px;

    .value {
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.16);
      padding: 9px;
      border-radius: 3px;
      float: right;
    }
  }

}

/*Dropdown select*/
.dropdown-select {
  .ui.fluid.selection.dropdown {
    border-radius: 0px;
    background-color: $dropdown-bg-color;
    border: none;

    .menu {
      border: none;
      border-radius: 0px;
      background-color: $dropdown-bg-color;
    }
  }
}

.ui[class*="top aligned"].grid > .column:not(.row) {
  align-self: center !important;
}

.active-dot {
  font-family: $helvetica-bold;
  font-size: 14px !important;
}

.ui.vertical.tabular.menu .item.visited-section {
  background: $visited-section-color;
  border-bottom: 1px solid #cac5c526;
  //pointer-events: none;
}
.ui.vertical.tabular.menu {
  //pointer-events: none;
}

.how-information-content {
  padding: 5% !important;

  p {
    font-size: 15px;
  }
}

.review-header {
  position: fixed;
  left: 55%;
  font-size: 20px;
  right: 0;
  top: 5%;
}


.score-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
  max-height: 150px;
  font-family: $helvetica-bold;
  padding-top: 10%;
}
.score-section-title-normal {
  font-size:14px;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  margin-top: 30% !important;
  height: 200px;

}
.score-section-title-scored {
  font-size:12px;
  margin: 0 auto;
  display: table-cell;
  vertical-align: middle;
  margin-top: 25% !important;
  height: 200px;
}


.icon-scroll, .icon-scroll:before {
  position: absolute;
  left: 50%;
  z-index: 1208;
}
.icon-scroll {
  width: 20px;
  height: 35px;
  margin-left: -20px;
  top: 50%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #000;
  border-radius: 25px;
  left: 90%;
}
.icon-scroll:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #000;
  margin-left: -2px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@-moz-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(23px);
  }
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(23px);
  }
}
@-o-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(23px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(23px);
  }
}

.dimmer-wrapper-container{
  z-index: 1208;
  position: fixed;
  bottom: 50px;
  right:50px;
  font-size:18px;
  width: 350px;
  color: #bbb !important;
  float: left;
  font-family: $helvetica-regular;
}

.dimmer-wrapper-section {
  z-index: 1208;
  position: fixed;
  bottom: 5px;
  left:120px;
  font-size:18px;
  width: 350px;
  color: #bbb !important;
  float: left;
  font-family: $helvetica-regular;
}
.ui.modal .close {
  background: #fff;
  color: #ff3000 !important;
  border-radius: 50%;
  z-index: 1218;
}
.info.circular.icon.info-popup{
  cursor: pointer;
}

.ie-notice {
  background: rgba(251, 203, 204, 0.72);
  position: fixed;
  top: 0;
  right: 10%;
  left: 10%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: $helvetica-bold;
  .ie-notice-content {
    font-size: 13px;
    color: rgba(48, 48, 48, 0.85);
    text-align: center;
  }

}

@media  screen and (max-width: 1281px ) and (min-height:796px) {

  .lens {
    > img {
      max-height: 100vh;
      width: auto;
      height: auto;
      max-width: unset;
      top: 15%;
      right: 0;
      left: 12%;
      -webkit-transform: scale(1.32);
      -moz-transform: scale(1.32);
      -ms-transform: scale(1.32);
      -o-transform: scale(1.32);
      transform: scale(1.32);
    }
  }
  .survey-list {
    .column.lens-div {
      height: 100vh;
      .lens {
        position: absolute;
        top: -140px;
        bottom: 0px;
        right: -60px;
        left: 0px;

        svg {
          max-width: 100%;
          -webkit-transform: scale(1.5);
          -moz-transform: scale(1.5);
          -ms-transform: scale(1.5);
          -o-transform: scale(1.5);
          transform: scale(1.5);
        }
      }
    }
  }

  /*Question list inside lens division*/
  .questions-list {
    padding: 0 15%;
    .question-item {
      .question {
        font-size: 18px;
        .info-popup {
          font-size: 14px;
          cursor: pointer !important;
        }
      }
    }
    > .blur {
      .question {
        font-size: 17px;
      }
    }
  }
  .score-card {
    bottom: 10%;
  }
  .ui.bottom.attached.segment.active.tab .next-button {
    bottom: 15%;
    right: 175px;
  }
  .score-cards-container {
    top: 50%;
  }
  .how-final-score {
    // bottom: 15%;
    // right: 200px;
  }
  .section-details-sp {
    >img {
      width: 150px;
      margin-left: 100px;
    }
  }
}