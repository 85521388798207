$section-1-color: #243F90;
$section-2-color: #769B4B;
$section-3-color: #198496;
$section-4-color: #682074;
$section-5-color: #E1504C;
$section-6-color: #00758D;
$section-7-color: #009FE4;
$section-8-color: #D54A78;

.dotNav {
  border-radius: 50%;
  cursor: pointer;
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 10px;
}

.nav-indicator {
  position: fixed;
  display: flex;
  align-items: center;
  top: 5%;
  right: 10%;
}

.dot {
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;

  &.active-1 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #243F9040;
    border: 1px solid #243F9070;
    animation: pulse-1 2s infinite;
  }

  &.active-2 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #769B4B40;
    border: 1px solid #769B4B50;
    animation: pulse-2 2s infinite;
  }

  &.active-3 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #19849640;
    border: 1px solid #19849650;
    animation: pulse-3 2s infinite;
  }

  &.active-4 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #68207440;
    border: 1px solid #68207450;
    animation: pulse-4 2s infinite;
  }

  &.active-5 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #E1504C40;
    border: 1px solid #E1504C50;
    animation: pulse-5 2s infinite;
  }

  &.active-6 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #00758D40;
    border: 1px solid #00758D50;
    animation: pulse-6 2s infinite;
  }

  &.active-7 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #009FE440;
    border: 1px solid #009FE450;
    animation: pulse-7 2s infinite;
  }

  &.active-8 {
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #D54A7840;
    border: 1px solid #D54A7850;
    animation: pulse-8 2s infinite;
  }
}

;

.section-1 {
  background-color: $section-1-color;
}

.section-2 {
  background-color: $section-2-color;
}

.section-3 {
  background-color: $section-3-color;
}

.section-4 {
  background-color: $section-4-color;
}

.section-5 {
  background-color: $section-5-color;
}

.section-6 {
  background-color: $section-6-color;
}

.section-7 {
  background-color: $section-7-color;
}

.section-8 {
  background-color: $section-8-color;
}

@-webkit-keyframes pulse-1 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #243F9040;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-1 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #243F9040;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #243F9040;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@-webkit-keyframes pulse-2 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #769B4B40;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-2 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #769B4B40;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #769B4B40;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@-webkit-keyframes pulse-3 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #19849640;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-3 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #19849640;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #19849640;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@-webkit-keyframes pulse-4 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #68207440;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-4 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #68207440;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #68207440;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@-webkit-keyframes pulse-5 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #E1504C40;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-5 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #E1504C40;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #E1504C40;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@-webkit-keyframes pulse-6 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #00758D40;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-6 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #00758D40;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #00758D40;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@-webkit-keyframes pulse-7 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #009FE440;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-7 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #009FE440;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #009FE440;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@-webkit-keyframes pulse-8 {
  0% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #D54A7840;
  }
  70% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -webkit-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}

@keyframes pulse-8 {
  0% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #D54A7840;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #D54A7840;
  }
  70% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 10px #fff;
  }
  100% {
    -moz-box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff, 0 0 0 2px #fff;
  }
}