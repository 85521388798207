@import './variables.scss';

.about-you {
  background-color: $about-you-bg-color;
  position: fixed;
  height: 100%;
  .column.lens-div {
    padding: 0px !important;
    margin: 0px !important;
    position: absolute;

    .lens {
      position: absolute;
      top: -140px;
      bottom: 0px;
      right: -60px;
      left: 0px;

      svg {
        max-width: 100%;
      }
    }
    .heading {
      font-family: $helvetica-bold;
    }
  }

  .tell-us-section {
    font-size: 3vw;
    line-height: 4vw;
    color: #ffffff;
    margin-top: 4em;
  }

  .about-you-content {
    margin: 0 20%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    .gender-select {
      margin: 0px;

      .title {
        text-align: center;
        display: block;
      }

      img {
        cursor: pointer;
        max-width: 50%;
      }

      .ui.circular.button {
        background-color: $gender-select-button-color;
        color: #ffffff;
        position: absolute;
        right: 0px;
        top: -15px;
      }
    }

    .other-info {
      .range-slider, .dropdown-select {
        width: 100%;
      }

      .heading {
        margin-bottom: 30px;
      }

      .submit-button-div {
        display: block;
        text-align: right;

        button {
          background-color: $about-you-bg-color;
          color: #ffffff;

          &:focus, :hover {
            background-color: $about-you-bg-color;
            color: #ffffff;
          }
        }
      }
    }
  }
}