/*Fonts*/
@font-face {
  font-family: "HelveticaRg";
  src: url('../fonts/HelvLight-Regular.woff') format('woff'),
  url('../fonts/HelvLight-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "HelveticaBd";
  src: url('../fonts/HELR65W.woff') format('woff'),
  url('../fonts/HELR65W.ttf') format('truetype');

}


$section-1-color: #243F90;
$section-2-color: #769B4B;
$section-3-color: #198496;
$section-4-color: #682074;
$section-5-color: #E1504C;
$section-6-color: #00758D;
$section-7-color: #009FE4;
$section-8-color: #D54A78;
$visited-section-color: #707070;
$question-font-color: #525252;
$button-hover-color: #78BE20;
$button-border-color: #A8A8A8;
$button-text-color: #333333;
$reaction-color: #A8A8A8;
$track-color: #EAEAEA;
$dot-text-color: #000;
$dropdown-bg-color: #F2F2F2;
$dropdown-font-color: #585858;
$about-you-bg-color: #84A65A;
$gender-select-button-color: #77BD20;
$tab-font-size: 48px;
$range-slider-bg-color: #404B54;

/*Font variables*/
$helvetica-regular: "HelveticaRg";
$helvetica-bold: "HelveticaBd";