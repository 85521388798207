@import './variables';

.sc-wrapper {
  position: relative;
  // height: 500px;
  height: 435px;
}

/* Score card - large */
.fn-score-card {
  background: url('/score-card-sprite.png') no-repeat;
  position: absolute;
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transform: scale(0.85);
  cursor: pointer;
  opacity: 0;
  -webkit-animation: score 1s forwards;
  -o-animation: score 1s forwards;
  animation: score 1s forwards;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5));
  transition: 0.25s linear;
  font-size: 16px;
  font-family: $helvetica-regular;

  & span {
    font-size: 20px;
  }
}

.fn-score-card:hover{
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  -o-transform: scale(1.03);
  transform: scale(1.03);
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -ms-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  font-family: $helvetica-bold;
  font-size: 16px;
}

.fn-score-card > div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  width: 60%;
  margin: 0 auto;
}

.disc {
  background: url('/score-card-sprite.png') no-repeat -868px -30px;
  position: absolute;
  width: 182px;
  height: 182px;
  top: 156px;
  left: 152px;
}

.disc > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #6C6B6B;
}

.sc-section-1 {
  background-position: -31px -39px;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  width: 176px;
  height: 165px;
  top: 16px;
  left: 156px;
}

.sc-section-2 {
  background-position: -239px -31px;
  -webkit-animation-delay: 1.0s;
  -moz-animation-delay: 1.0s;
  -o-animation-delay: 1.0s;
  animation-delay: 1.0s;
  width: 180px;
  height: 180px;
  top: 34px;
  left: 278px;
}

.sc-section-3 {
  background-position: -457px -33px;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
  width: 165px;
  height: 176px;
  top: 160px;
  left: 312px;
}

.sc-section-4 {
  background-position: -659px -31px;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
  width: 180px;
  height: 180px;
  top: 282px;
  left: 278px;
}

.sc-section-5 {
  background-position: -31px -250px;
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s;
  width: 176px;
  height: 165px;
  top: 316px;
  left: 156px;
}

.sc-section-6 {
  background-position: -239px -242px;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
  width: 180px;
  height: 180px;
  top: 282px;
  left: 30px;
}

.sc-section-7 {
  background-position: -457px -244px;
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  -o-animation-delay: 3.5s;
  animation-delay: 3.5s;
  width: 165px;
  height: 176px;
  top: 160px;
  left: 10px;
}

.sc-section-8 {
  background-position: -659px -241px;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
  width: 180px;
  height: 180px;
  top: 34px;
  left: 30px;
}

.score-value {
  display: none;
  font-size: 50px;
  font-family: $helvetica-bold;
}

.score-value.small {
  font-size: 25px;
}

.card:hover .score-value,.hoveredCard .score-value {
  display: block;
}

/* Score card - small */
.score-card-small {
  background: url('/score-card-sprite.png') no-repeat;
  position: absolute;
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transform: scale(0.85);
  opacity: 0;
  -webkit-animation: score 1s forwards;
  -o-animation: score 1s forwards;
  animation: score 1s forwards;
  cursor: pointer;
  -webkit-transition: 0.25s linear;
  -moz-transition: 0.25s linear;
  -ms-transition: 0.25s linear;
  -o-transition: 0.25s linear;
  transition: 0.25s linear;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5));

  & span {
    font-size: 14px;
  }
}

.score-card-small:hover {
  transform: scale(1.03);
  transition: 0.25s linear;
  font-family: $helvetica-bold;
}
.hoveredCard {
  transform: scale(1.03);
  transition: 0.25s linear;
  font-family: $helvetica-bold;
}

.score-card-small > div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  width: 60%;
  margin: 0 auto;
  font-size: 12px;
}

.disc-small {
  background: url('/score-card-sprite.png') no-repeat -892px -283px;
  width: 122px;
  height: 121px;
  position: absolute;
  top: 106px;
  left: 110px;
}

.disc-small > div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: #6C6B6B;
  font-size: 12px;
}

.sc-section-small-1 {
  background-position: -60px -510px;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  width: 119px;
  height: 112px;
  top: 8px;
  left: 110px;
}

.sc-section-small-2 {
  background-position: -268px -505px;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  width: 122px;
  height: 122px;
  top: 22px;
  left: 196px;
}

.sc-section-small-3 {
  background-position: -483px -507px;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
  width: 112px;
  height: 119px;
  top: 108px;
  left: 220px;
}

.sc-section-small-4 {
  background-position: -688px -505px;
  -webkit-animation-delay: 2.0s;
  -moz-animation-delay: 2.0s;
  -o-animation-delay: 2.0s;
  animation-delay: 2.0s;
  width: 122px;
  height: 122px;
  top: 194px;
  left: 196px;
}

.sc-section-small-5 {
  background-position: -60px -721px;
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s;
  width: 119px;
  height: 112px;
  top: 218px;
  left: 110px;
}

.sc-section-small-6 {
  background-position: -268px -716px;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
  width: 122px;
  height: 122px;
  top: 194px;
  left: 24px;
}

.sc-section-small-7 {
  background-position: -483px -718px;
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  -o-animation-delay: 3.5s;
  animation-delay: 3.5s;
  width: 112px;
  height: 119px;
  top: 108px;
  left: 10px;
}

.sc-section-small-8 {
  background-position: -688px -715px;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
  width: 122px;
  height: 122px;
  top: 22px;
  left: 24px;
}

.score-cards-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  padding-left: 100px;
  // top: 60%;
  top: 48%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100%;
  overflow-y: auto;
}

.final-score-section {
  background: $section-1-color;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.section-details-sp {
  display: table-cell;
  vertical-align: middle;
  >img {
    width: 200px;
    margin-left: 100px;
  }
}

.center-overall-score-large {
  font-family: $helvetica-bold;
  font-size: 21px;
}

.center-overall-score-large div {
  font-size: 21px;
}

.center-overall-score-large .obtained {
  font-size: 35px;
}

.score-header {
  font-size: 36px !important;
  font-family: $helvetica-bold;
  color: #303030d9 !important;
  &.people-like-you{
    font-size: 24px !important;
    >span {
      font-size: 25px;
      cursor: pointer;
    }
  }
  >span {
    font-size: 25px;
    cursor: pointer;
  }
}

.how-final-score {
  position: fixed;
  bottom: 40px;
  right: 100px;
  font-family: $helvetica-bold;
  font-size: 15px;
  color: #00008B;
  cursor: pointer;
}
.low-score-section-names,.phone-number {
  font-family: $helvetica-bold;
  color: #4183c4;
}
.low-score-section-names{
  &.normal{
    color: #000000;
  }
}
.phone-number {
  font-size: 18px;
}

.center-overall-score-small {
  font-family: $helvetica-bold;

  .obtained {
    font-size: 30px;
  }
}
.info-icon-scores {
  position: fixed;
  left: 150px;
  bottom: 5%;
  color:#fff;
  z-index: 10;
  font-size: 60px;
  cursor: pointer;
  animation: button-animation 0.5s infinite alternate;
}
.score-final-information {
  padding: 25px;
  font-size: 18px !important;
  font-family: $helvetica-regular;
}

#result {
  content: '';
  display: table;
  clear: both;
  width: 100%;
}

@-webkit-keyframes score {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes score {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width:1634px) {
  .mini-score-cards-container {
    width: 56% !important;
  }
}

@media (min-width:1600px) {
  #logo {
    margin-left: 150px;
  }
}

@media (min-width:1800px) {
  .your-score-title{
    margin-left: -125px;
  }
}

@media (max-width:1322px) {
  html {
    font-size: 12px;
  }
}

// @media (min-width:1700px) {
//   .sc-wrapper {
//     left: 60px;
//   }
// }
// @media (min-width:1700px) {
//   .sc-wrapper {
//     left: 85px;
//   }
// }