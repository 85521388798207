@import './variables';

.ui.vertical.tabular.menu {
  width: 5.9% !important;
  position: fixed;
  left: 0px;
  z-index: 1;
  border-right: none;
  height: 100%;

  .item {
    padding: 30%;
    padding-left: 25%;
    font-size: $tab-font-size;
    color: #ffffff;
    border-radius: 0px;
    text-align: center;
    height: 12.5% !important;
    border: none;
    //pointer-events: none;
    &.section-1 {
      background-color: $section-1-color;
    }

    &.section-2 {
      background-color: $section-2-color;
    }

    &.section-3 {
      background-color: $section-3-color;
    }

    &.section-4 {
      background-color: $section-4-color;
    }

    &.section-5 {
      background-color: $section-5-color;
    }

    &.section-6 {
      background-color: $section-6-color;
    }

    &.section-7 {
      background-color: $section-7-color;
    }

    &.section-8 {
      background-color: $section-8-color;
    }
  }

  .active.item {
    color: #ffffff;
    border-radius: 0px !important;
    margin: 0px;
  }
}

.ui.bottom.attached.segment.active.tab {
  border-left: none;
  border-top: none;
  padding: 0px;

  .next-button {
    margin-top: 30px;
    text-align: right;
    position: fixed;
    bottom: 10%;
    right:150px;
    button {
      border-radius: 0px;
      background: $section-1-color;
      border: 1px solid $section-1-color;
      color: #ffffff;
      font-family: $helvetica-bold;
      padding-left: 50px;
      padding-right: 50px;
      font-size: 18px !important;
      animation: button-animation 0.5s infinite alternate;
      &:hover, &:focus {
        background: $section-1-color;
        border: 1px solid $section-1-color;
        color: #ffffff;
      }
    }
  }

  &.section-1-content {
    background-color: $section-1-color;
    height: 102vh;
    overflow: hidden;
    border: 0;

    .next-button {
      button {
        background: $section-1-color;
        border: 1px solid $section-1-color;

        &:hover, &:focus {
          background: $section-1-color;
          border: 1px solid $section-1-color;
        }
      }
    }

    .score-card {
      color: $section-1-color;
    }
  }

  &.section-2-content {
    background-color: $section-2-color;
    height: 102vh;
    overflow: hidden;
    border: 0;

    .next-button {
      button {
        background: $section-2-color;
        border: 1px solid $section-2-color;

        &:hover, &:focus {
          background: $section-2-color;
          border: 1px solid $section-2-color;
        }
      }
    }

    .score-card {
      color: $section-2-color;
    }
  }

  &.section-3-content {
    background-color: $section-3-color;
    height: 102vh;
    overflow: hidden;

    .next-button {
      button {
        background: $section-3-color;
        border: 1px solid $section-3-color;

        &:hover, &:focus {
          background: $section-3-color;
          border: 1px solid $section-3-color;
        }
      }
    }

    .score-card {
      color: $section-3-color;
    }
  }

  &.section-4-content {
    background-color: $section-4-color;
    height: 102vh;
    overflow: hidden;

    .next-button {
      button {
        background: $section-4-color;
        border: 1px solid $section-4-color;

        &:hover, &:focus {
          background: $section-4-color;
          border: 1px solid $section-4-color;
        }
      }
    }

    .score-card {
      color: $section-4-color;
    }
  }

  &.section-5-content {
    background-color: $section-5-color;
    height: 102vh;
    overflow: hidden;

    .next-button {
      button {
        background: $section-5-color;
        border: 1px solid $section-5-color;

        &:hover, &:focus {
          background: $section-5-color;
          border: 1px solid $section-5-color;
        }
      }
    }

    .score-card {
      color: $section-5-color;
    }
  }

  &.section-6-content {
    background-color: $section-6-color;
    height: 102vh;
    overflow: hidden;

    .next-button {
      button {
        background: $section-6-color;
        border: 1px solid $section-6-color;

        &:hover, &:focus {
          background: $section-6-color;
          border: 1px solid $section-6-color;
        }
      }
    }

    .score-card {
      color: $section-6-color;
    }
  }

  &.section-7-content {
    background-color: $section-7-color;
    height: 102vh;
    overflow: hidden;

    .next-button {
      button {
        background: $section-7-color;
        border: 1px solid $section-7-color;

        &:hover, &:focus {
          background: $section-7-color;
          border: 1px solid $section-7-color;
        }
      }
    }

    .score-card {
      color: $section-7-color;
    }
  }

  &.section-8-content {
    background-color: $section-8-color;
    height: 102vh;
    overflow: hidden;

    .next-button {
      button {
        background: $section-8-color;
        border: 1px solid $section-8-color;

        &:hover, &:focus {
          background: $section-8-color;
          border: 1px solid $section-8-color;
        }
      }
    }

    .score-card {
      color: $section-8-color;
    }
  }
}

@-webkit-keyframes button-animation {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

}

@-o-keyframes button-animation {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

}

@-moz-keyframes button-animation {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

}

@keyframes button-animation {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

}
