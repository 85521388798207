@import "./variables.scss";

.home-main-div {
  width: 100%;
  margin: 0px;

  .home-content {
    background: #39519A;
    padding: 5%;
    font-family: $helvetica-regular;
    color: #ffffff;
    max-width: 80%;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    width: 70%;
    left: -700px;
    animation: extend-div 1s ease-out forwards;

    .home-title {
      font-size: 40px;
      white-space: nowrap;
      overflow: hidden;
      line-height: normal;
      position: relative;
      margin-bottom: 1%;
      font-family: $helvetica-bold;
      text-align: center;
      //opacity: 0;
      //animation: fade-in-title 2s forwards;
      //animation-delay: 1s;
    }

    .home-description {
      font-size: 40px;
      position: relative;
      line-height: normal;
      text-align: center;
      //opacity: 0;
      //animation: fade-in-title 1s forwards;
      //animation-delay: 0.5s;
    }
  }

  .start-button {
    text-align: center;
    left:0;
    right:0;
    top: 70%;
    z-index: 35;
    position: absolute;

    a {
      padding: 15px 50px;
      font-size: 16px;
      background: #39519A !important;
    }
    &:hover {
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
      transition: 0.2s linear;
    }
  }

  .logo-image {
    position: absolute;
    top: 50%;
    left: -130px;
    max-width: 35%;
    z-index: 2;
    transform: translateY(-50%);
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);

    img.ui.image.logo-white {
      position: absolute;
      width: 40%;
      // left: 165px;
      left: 28%;
      transform:translateY(-50%);
      -webkit-transform:translateY(-50%);
      -ms-transform:translateY(-50%);
      top:50%;
    }
  }
}

.our-methodology {
  color: #00008B;
  font-family: $helvetica-bold;
  position: fixed;
  bottom: 50px;
  cursor: pointer;
  right: 100px;
  font-size: 1.3em;
}

@-webkit-keyframes extend-div {
  0% {
    left: -700px;
  }
  100% {
    left: 183px;
  }
}

@keyframes extend-div {
  0% {
    left: -700px;
  }
  100% {
    left: 183px;
  }
}

@keyframes fade-in-title  {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-title  {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}